import { apiWithdrawalBlacklist } from '@/resource'
import { apiDepositBlacklist } from '@/resource'
import { apiTransferBlacklist } from '@/resource'

export default {
  methods: {
    getDepositBlacklist() {
      apiDepositBlacklist().then(resp => {
        resp.data.data
          ? this.$router.push({ path: '/depositFunds' })
          : this.$message({
            message: this.$t('responseMsg.568'),
            type: 'error',
          })
      })
    },
    getWithdrawalBlacklist() {
      apiWithdrawalBlacklist().then(resp => {
        resp.data.data
          ? this.$router.push({ path: '/withdrawFunds' })
          : this.$message({
            message: this.$t('withdraw.default.blackList', { supportEmail: this.GLOBAL_CONTACT_EMAIL }),
            type: 'error',
          })
      })
    },
    goToTransferFunds() {
      this.getTransferBlacklist().then(resp => {
        resp ? this.$router.push({ name: 'transferBetweenAccs', params: { passBlacklist: true } }) : this.transferErrMsg()
      })
    },
    async getTransferBlacklist() {
      return new Promise(resolve => {
        apiTransferBlacklist().then(resp => {
          resolve(resp.data.data)
        })
      })
    },
    transferErrMsg() {
      this.$message({
        message: this.$t('transferFunds.default.blackList', { supportEmail: this.GLOBAL_BLACKLIST_EMAIL }),
        type: 'error',
      })
    },
  },
}
