function millionDollarCashPool(width) {
  switch (true) {
    case width > 1500:
      return 'long'
    case width <= 1500 && width > 1280:
      return 'mid-long'
    case width < 600:
      return 'xShort'
    case width < 870:
      return 'short'
    case width < 1000:
      return 'mid-short'
    default:
      return 'mid'
  }
}

function firstTimeDeposit(width) {
  switch (true) {
    case width > 1660:
      return 'long'
    case width <= 1660 && width > 1400:
      return 'mid-long'
    case width < 735:
      return 'xx-short'
    case width < 810:
      return 'x-short'
    case width < 955:
      return 'short'
    case width < 1100:
      return 'mid-short'
    default:
      return 'mid'
  }
}

export { millionDollarCashPool, firstTimeDeposit }
