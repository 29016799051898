<template>
  <div
    :class="`${promotionName} ${lang} ${bnClass} ${ltr ? 'ltr' : ''}`"
    class="bn-container"
    :dir="ltr ? 'ltr' : ''"
    v-resizeObserver="{
      openResize: openResize,
      handler: observerCallBack
    }"
  >
    <div class="bn-info">
      <div class="bn-info-context" v-html="$t(title)" />
    </div>
    <div v-if="leftSideImgUrl" class="left-side-img">
      <img :src="leftSideImgUrl" alt />
    </div>
    <div v-if="rightSideImgUrl" class="right-side-img">
      <img :src="rightSideImgUrl" alt />
    </div>
  </div>
</template>

<script>
import * as BANNER_BREAKPOINTS from '@/util/bannerModel';

export default {
  name: 'BannerItemV2',
  props: {
    promotionName: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    leftSideImg: {
      type: Object,
      default: () => ({})
    },
    rightSideImg: {
      type: Object,
      default: () => ({})
    },
    extraClass: {
      type: Array,
      default: () => []
    },
    lang: {
      type: String,
      default: 'en_US'
    },
    ltr: {
      type: Boolean,
      default: false
    },
    openResize: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      bannerWidth: 0
    };
  },
  computed: {
    bnClass() {
      if (!this.openResize || !BANNER_BREAKPOINTS[this.promotionName]) return '';

      let finalClasses = [...this.extraClass];
      const currentCalcBannerRwdClass = BANNER_BREAKPOINTS[this.promotionName](this.bannerWidth);
      finalClasses.push(currentCalcBannerRwdClass);
      this.$emit('getCurrentBannerRwdClass', currentCalcBannerRwdClass);
      return finalClasses.join(' ');
    },
    leftSideImgUrl() {
      if (!Object.keys(this.leftSideImg).length) return '';

      const { img, imgSmall, breakpoint } = this.leftSideImg;
      if (!breakpoint) return img;
      return this.bannerWidth > Number(breakpoint) ? img : imgSmall;
    },
    rightSideImgUrl() {
      if (!Object.keys(this.rightSideImg).length) return '';

      const { img, imgSmall, breakpoint } = this.rightSideImg;
      if (!breakpoint) return img;
      return this.bannerWidth > Number(breakpoint) ? img : imgSmall;
    }
  },
  methods: {
    observerCallBack(width) {
      this.bannerWidth = width;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/banner/v2/bannerItem.scss';
@import '@/assets/css/components/banner/v2/firstTimeDeposit.scss';
@import '@/assets/css/components/banner/v2/millionDollarsCashPool.scss';
</style>
