<template>
  <div class="swiper_box">
    <el-carousel trigger="click" height="255px" :interval="interval">
      <el-carousel-item
        v-for="(item, index) in data"
        :key="index"
        @click.native="clickBannerUrl(item.bannerUrl)"
        :style="{
          backgroundImage: 'url(' + item.img + ')',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: item.backgroundPosition ? item.backgroundPosition : 'center center',
          backgroundSize: 'cover'
        }"
      >
        <BannerItemV1 v-if="item.bannerVersion === 'v1'" v-bind="item" :lang="imgLang" />
        <BannerItemV2 v-else-if="item.bannerVersion === 'v2'" v-bind="item" :lang="imgLang" />
        <div v-else class="info">
          <h2 v-if="item.info.title" v-html="$t(item.info.title)"></h2>
          <h4 v-if="item.info.tip">
            {{ item.info.tip }}
          </h4>
          <a :href="item.url" :target="item.target || '_blank'" v-if="item.button">
            {{ item.button }}
          </a>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import BannerItemV1 from './banner/item/v1/BannerItem.vue';
import BannerItemV2 from './banner/item/v2/BannerItem.vue';

export default {
  props: { banners: Array },
  components: {
    BannerItemV1,
    BannerItemV2
  },
  data() {
    return {
      interval: 10000,
      windowWidth: window.innerWidth
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    clickBannerUrl(url) {
      if (url) this.$router.push({ path: url });
    },
    isDepositBonus(type) {
      return type === 'DEPOSIT_BONUS_2' ? true : false;
    },
    isCashBackBonus(type) {
      return type === 'CASHBACK';
    },
    getTypeId(type) {
      return this.campaignEligibility.find(el => el.campaignType === type).id;
    },
    bannerLink(imgPath) {
      try {
        return require(`@/assets/images/banner/${imgPath}${this.imgLang}.png`);
      } catch (e) {
        return require(`@/assets/images/banner/${imgPath}en_US.png`);
      }
    }
  },
  computed: {
    campaignEligibility() {
      return this.$store.state.promotion.eligibleCampaigns;
    },
    imgLang() {
      return this.$store.state.common.lang;
    },
    data() {
      const result = [];
      const campaignType = this.campaignEligibility.map(m => {
        return m.campaignType;
      });
      this.banners.forEach(key => {
        if (!key.promotionType || campaignType.includes(key.promotionType)) {
          if (this.isDepositBonus(key.promotionType)) {
            if (this.getTypeId(key.promotionType) !== key.promotionId) return;
          }

          if (this.isCashBackBonus(key.promotionType)) {
            if (this.getTypeId(key.promotionType) !== key.promotionId) return;
          }

          if (key.info.multiLang) {
            result.push({
              ...key,
              img: `${this.bannerLink(key.img)}`
            });
            return;
          }

          if (key.imgSmall) {
            let img = this.windowWidth <= 414 ? key.imgSmall : key.img;
            if (this.imgLang === 'ar' && this.windowWidth <= 414 && key.imgSmallAr) {
              img = key.imgSmallAr;
            }
            let title = key.title;

            result.push({
              ...key,
              img,
              title
            });
            return;
          }

          result.push(key);
        }
      });

      return result;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/layout/banner.scss';
</style>
