<template>
  <header>
    <div class="left">
      <img v-show="isMobile" class="logo" src="@/assets/images-1/dark_logo_icon.png" alt="" />
      <img src="@/assets/images-1/menu_collapse.png" @click="openMenu" v-show="onOpen && !isCollapse" alt="close menu"
        data-testid="closeMenu" />
      <img src="@/assets/images-1/menu_expand.png" @click="openMenu" v-show="isCollapse || matches" alt="open menu"
        data-testid="openMenu" />
      <div class="page_title" v-show="getPageTitle && !isMobile">{{ getPageTitle }}</div>
    </div>
    <div class="right">
      <el-button v-show="!isMobile" type="primary" plain style="margin: 0 8px;">
        <svg-icon iconClass="deposit"></svg-icon>
        {{ $t('common.button.depositFunds') }}
      </el-button>
      <PortalSwitch @click="handleIb" v-show="isIb" data-testid="goToIbPortal"></PortalSwitch>
      <el-divider v-if="!isMobile || isIb" direction="vertical"></el-divider>
      <HeaderNotification></HeaderNotification>
      <Language></Language>
      <el-dropdown trigger="click" @command="handleCommand">
        <div class="login-inner">
          <img src="@/assets/images-1/avatar.png" alt class="member-logo" />
        </div>
        <el-dropdown-menu slot="dropdown" class="login_dropdown_box">
          <div class="name_wrapper">
            <img src="@/assets/images-1/avatar.png" alt class="member-logo" />
            <span class="name" v-html="userName"></span>
          </div>
          <el-dropdown-item command="myProfile" class="myProfile" data-testid="myProfile">
            <div class="icon"></div>
            <span>{{ $t('menu.myProfile') }}</span>
          </el-dropdown-item>
          <el-dropdown-item command="cpPassword" class="cpPassword" data-testid="cpPassword">
            <div class="icon"></div>
            <span>{{ $t('menu.cpPassword') }}</span>
          </el-dropdown-item>
          <el-dropdown-item divided command="logout" class="logout" data-testid="logout">
            <div class="icon"></div>
            <span>{{ $t('common.button.logout') }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </header>
</template>

<script>
import helper from '@/util/signinHelper'
import HeaderNotification from '@/components/notification/HeaderNotification'
import Language from './Language.vue'
import PortalSwitch from './PortalSwitch.vue'
import isMobile from '@/mixins/isMobile'

export default {
  name: 'vHeader',
  components: { HeaderNotification, Language, PortalSwitch },
  mixins: [isMobile],
  props: { matches: Boolean, onOpen: Boolean, isCollapse: Boolean },
  data() {
    return {
      isIb: false,
    }
  },
  computed: {
    userName() {
      return this.$store.state.common.userName
    },
    loginStatus() {
      return this.$store.state.common.loginStatus
    },
    userType() {
      return this.$store.state.common.userType
    },
    getPageTitle() {
      const key = `menu.${this.$route.name}`
      return this.$te(key) ? this.$t(`menu.${this.$route.name}`) : false
    },
  },
  methods: {
    openMenu() {
      this.$emit('openMenu')
    },
    logout() {
      helper.signOut()
    },
    handleCommand(command) {
      if (command == 'logout') this.logout()
      else if (command == 'myProfile') this.$router.push('/myProfile')
      else if (command == 'cpPassword') this.$router.push('/changePassword')
      else if (command == 'goToIbPortal' && this.isIb) this.$redirect.redirectToIbPortal()
    },
    handleIb() {
      this.$redirect.redirectToIbPortal()
    },
    getUserInfo() {
      this.isIb = this.userType === 5 || this.userType === 16 ? true : false
    },
  },
  mounted() {
    this.getUserInfo()
    //watch change foo key and triggered callback
    this.$ls.on('setUserName', (val, oldVal, uri) => {
      if (val != this.userName) location.reload()
    })
  },
}
</script>

<style lang="scss" scoped>
// @import '@/assets/css/layout/header.scss';
header {
  position: sticky;
  position: -webkit-sticky;
  z-index: 99;
  top: 0;
  height: 64px;
  display: flex;
  justify-content: space-between;
  background: $white;
  padding: 0 24px;

  .left {
    display: flex;
    align-items: center;
    height: 100%;

    .logo {
      width: 32px;
      @include rtl-sass-prop(margin-right, margin-left, 10px);
    }

    .page_title {
      font-weight: 600;
      font-size: 16px;
      @include rtl-sass-prop(margin-left, margin-right, 16px);
    }
  }

  .right {
    display: flex;
    align-items: center;
    height: 100%;

    .login-inner {
      width: 32px;
      height: 32px;
      cursor: pointer;
      @include rtl-sass-prop(margin-left, margin-right, 8px);
    }
  }
}
</style>

<style lang="scss">
.login_dropdown_box {
  padding: 8px;

  .el-dropdown-menu__item {
    line-height: 40px;
    font-size: 14px;
    border-radius: 4px;
  }

  .name_wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    background-color: rgba($warning, 0.2);
    border-radius: 4px;
    padding: 12px;

    .member-logo {
      width: 32px;
      height: 32px;
      margin-right: 8px;
    }

    .name {
      font-size: 14px;
      font-weight: 500;
      color: $text-secondary;
    }
  }
}
</style>
